import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IndustryImg from "../../../../images/industryImg.png";
import TelecommunicationIcon from "../../../../images/icons/telecommunicationIcon.svg";
import RetailAndHospitalityIcon from "../../../../images/icons/retailAndHospitalityIcon.svg";
import LocalGovernmentIcon from "../../../../images/icons/localGoverIcon.svg";
import EducationIcon from "../../../../images/icons/educationIcon.svg";
import PropertyIcon from "../../../../images/icons/propertyIcon.svg";
import * as style from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";

const IndustriesSection = ({ data }) => {
  const parm = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.`;
  let parmSubString = parm.substring(1, 80).concat("...");

  const [imageClicked, setImageClicked] = useState(IndustryImg);

  const onClickHandler = (e) => {
    setImageClicked(e);
  };

  return (
    <section id="industries" className={`bg-primary ${style.section_wrapper}`}>
      <div className="section-center">
        <Container fluid={"xxl"}>
          <Row>
            <Col md={6} className={style.section_cel}>
              <div className={style.topOuterBox}>
                <div className={style.topContentBox}>
                  <PrismicRichText field={data?.indus_title} />
                  <p>{data?.indus_description}</p>
                  {/*<Button>Button Text</Button>*/}
                </div>
                <div className={`text-right ${style.topImageBox}`}>
                  <img src={data?.indus_image} alt="localGovernment" />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Row>
                {data.indus_card?.map((item, idx) => (
                  <Col xs={6} key={idx} className={"text-center text-white"}>
                    <div className={style.industry_details_wrapper}>
                      <img src={item.indus_card_icon.url} alt="" />
                      <PrismicRichText field={item.indus_card_title} />
                      <PrismicRichText field={item.indus_card_description} />
                      {/*<h5 className={"text-white my-2"}>{item.indus_card_title}</h5>*/}
                      {/*<p className={"m-0"}>{item.indus_card_description}</p>*/}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default IndustriesSection;

const industries = [
  {
    icon: LocalGovernmentIcon,
    name: "Local Government",
    description:
      "KAYA helps local governments improve service delivery for their communities, making them better places to live, " +
      "work, and visit. Trust us to be your partner.",
  },
  {
    icon: EducationIcon,
    name: "Education",
    description:
      "For the past 20 years, we've provided facilities management to various clients such as schools and universities. " +
      "Our solutions blend hands-on experience with cutting-edge technology.",
  },
  {
    icon: RetailAndHospitalityIcon,
    name: "Health",
    description:
      "We work with hospitals & aged care facilities to create comfortable, safe, & dignified environments for patients, " +
      "residents, people with disabilities, families, & employees in the health sector.",
  },
  {
    icon: TelecommunicationIcon,
    name: "Education",
    description:
      "Our team of experts is dedicated to delivering superior design, build, and maintenance solutions and innovation " +
      "for critical communications networks.",
  },
  {
    icon: PropertyIcon,
    name: "RESIDENTIAL",
    description:
      "Long-term protection and presentation of your property through professional maintenance.",
  },
  {
    icon: TelecommunicationIcon,
    name: "COMMERCIAL, RETAIL, INDUSTRIAL AND WAREHOUSE ",
    description: "",
  },
];
