import React from "react";
import HeroImageBackground from "../../../../images/HeroImageBackground.jpg";
import * as style from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";

const HeroSection = ({ slice, data }) => {
  return (
    <>
      <section id="home" className={style.heroBox}>
        <img src={HeroImageBackground} className={style.backImg} alt={""} />
        <div className={style.overlay}>
          <div className={`section-center ${style.section}`}>
            <div className={` ${style.contentBox}`}>
              <PrismicRichText field={data.hero_title} />
              <PrismicRichText field={data.hero_description} />
            </div>
            <div className={style.foregroundContent}>
              <img
                src={data.hero_side_image_src}
                alt={data.hero_side_image_alt}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
