import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import * as style from "./index.module.scss";

const StyledFormikInput = ({
  label,
  name,
  type = "text",
  placeholder = " ",
  readOnly = false,
  as = "input",
  children = null,
  styleName = "",
}) => {
  const { errors, touched, status } = useFormikContext();
  const hasError = errors?.[name] && touched?.[name];

  let inputClass = as === "select" ? "form-select" : "form-control";

  return (
    <div className={`form-floating ${style.inputBox}`}>
      <Field
        as={as}
        type={type}
        name={name}
        placeholder={placeholder}
        className={`${inputClass} ${hasError ? "is-invalid" : ""}`}
        id={name}
        disabled={status?.success || readOnly}
        style={{ minHeight: as === "textarea" ? "189px" : "0px" }}
      >
        {children}
      </Field>
      <label htmlFor={name} className={"w-100"}>
        {label}
      </label>
      <ErrorMessage
        name={name}
        component="span"
        className={"invalid-feedback"}
      />
    </div>
  );
};

export default StyledFormikInput;
