import React from "react";
import StyledComponent from "../../../../components/StyledComponent";
import * as s from "./index.module.scss";

const TestimonialsSection = ({ data }) => {
  if (!data?.testimonials || !data?.testimonials?.length) return null;
  return (
    <div className={s.container}>
      <StyledComponent>
        <div className="text-center section-title">
          <h2>Testimonials</h2>
        </div>

        <div className="row justify-content-center">
          {data?.testimonials.map((item, index) => {
            return (
              <div
                className={"col-md-6 col-lg-4 text-center d-flex"}
                key={index}
                data-aos="zoom-in"
                data-aos-delay={100 + 100 * (index + 1)}
                data-aos-offset={400}
              >
                <div className={s.block}>
                  {item.image?.url && (
                    <img src={item.image?.url} alt={item.name} />
                  )}
                  <div>
                    <p>{item.content}</p>
                    <p>{item.name}</p>
                    <p>{item.designation}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </StyledComponent>
    </div>
  );
};

export default TestimonialsSection;
