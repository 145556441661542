import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import Logo from "../../../images/logo.png";
import { graphql, Link, useStaticQuery } from "gatsby";
import * as style from "./index.module.scss";

const expand = "md";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => setExpanded(false);
  const [toggle, setToggle] = useState(false);

  const scroll = (e) => {
    e.preventDefault();
    const url = window.location.href;
    const id = e.currentTarget.getAttribute("href").slice(2);
    const url_path = window.location.origin;

    if (url === url_path + "/terms-conditions/") {
      window.location.href = url_path;
    } else {
      const element = document.getElementById(id);
      const navHeight = document
        .querySelector(".my_Nav")
        .getBoundingClientRect().height;
      const linksHeight = document
        .querySelector(".links-nav")
        .getBoundingClientRect().height;
      let position = element.offsetTop - navHeight;
      if (navHeight > 100) {
        position += linksHeight;
      }
      window.scrollTo({
        top: position,
        left: 0,
      });
      setExpanded(false);
    }
  };

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicGlobals {
        data {
          pdf_file {
            url
          }
        }
      }
    }
  `);

  const pdf = data?.prismicGlobals?.data?.pdf_file?.url || null;

  return (
    <header>
      <Navbar
        bg="white"
        expand={expand}
        className={`mb-3 fixed-top my_Nav ${style.navBar} ${
          toggle ? "toggle" : ""
        }`}
        collapseOnSelect={true}
        expanded={expanded}
      >
        <div className={`section-center ${style.navBarContent}`}>
          <Navbar.Brand as="div">
            <Link to={"/"} className={style.logoText}>
              <img src={Logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={() => setExpanded(true)}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            onHide={closeMenu}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className={`justify-content-end flex-grow-1  links-nav  ${
                  toggle ? "toggle" : ""
                }`}
                as={"ul"}
              >
                <Nav.Link as="li">
                  <Link to={"/#home"} onClick={(e) => scroll(e)}>
                    Home
                  </Link>
                </Nav.Link>
                <Nav.Link as="li">
                  <NavDropdown
                    title="What We Do"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    as={"ul"}
                    className="nav_dropdown"
                  >
                    <NavDropdown.Item as="ul" className={style.sub_menu}>
                      <Link to={"/#services"} onClick={(e) => scroll(e)}>
                        Our Services
                      </Link>
                    </NavDropdown.Item>

                    {pdf && (
                      <NavDropdown.Item as="ul" className={style.sub_menu}>
                        <a href={pdf} target={"_blank"}>
                          Capability
                        </a>
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </Nav.Link>

                <Nav.Link as="li">
                  <Link to={"/#industries"} onClick={(e) => scroll(e)}>
                    Industries We Serve
                  </Link>
                </Nav.Link>
                <Nav.Link as="li">
                  <Link to={"/#aboutUs"} onClick={(e) => scroll(e)}>
                    About us
                  </Link>
                </Nav.Link>
                <Nav.Link as="li">
                  <Link to={"/#contactUs"} onClick={(e) => scroll(e)}>
                    Contact Us
                  </Link>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
