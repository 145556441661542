import React from "react";
import * as s from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";

const Values = ({ data }) => {
  return (
    <div className={s.container}>
      <div className="container">
        <div className="text-center section-title">
          <PrismicRichText field={data?.values_title} />
        </div>

        <div className="row justify-content-center">
          {data?.values.map(({ icon, title, description }, index) => {
            return (
              <div
                className={"col-sm-6 col-lg-4 d-flex"}
                key={index}
                data-aos="zoom-in"
                data-aos-delay={100 + 100 * (index + 1)}
                data-aos-offset={400}
              >
                <div className={s.value_block}>
                  <div>
                    <img src={icon?.url} alt={title} />
                    <h5>{title}</h5>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Values;
