import React from "react";
import * as s from "./index.module.scss";
import Vision from "./vision.svg";
import Mission from "./mission.svg";

const VisionMission = ({ data }) => {
  return (
    <div className={s.container}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-md-5">
                <h3 className={"d-flex d-md-none"}>
                  <img src={Mission} alt={"Mission"} />
                  Mission
                </h3>
                <div className={"image-with-shadow bottom-left"}>
                  <img
                    src={data?.vision_image?.url}
                    alt=""
                    data-aos="zoom-in-down"
                  />
                  <div data-aos="zoom-in-left"></div>
                </div>
              </div>
              <div className="col-md-7 align-items-center d-flex">
                <div className={"py-4 ps-md-5"}>
                  <h3 className={"d-none d-md-flex"}>
                    <img src={Mission} alt={"Mission"} />
                    Mission
                  </h3>
                  {data?.mission?.split("\n")?.map((text) => (
                    <p>{text}</p>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-5 order-md-2">
                <h3 className={"d-flex d-md-none pb-3"}>
                  <img src={Vision} alt={"Vision"} />
                  Vision
                </h3>
                <div className={"image-with-shadow top-right"}>
                  <img
                    src={data?.vision_image?.url}
                    alt=""
                    data-aos="zoom-in-left"
                  />
                  <div data-aos="zoom-in-right"></div>
                </div>
              </div>

              <div className="col-md-7 order-md-1 align-items-center d-flex">
                <div className={"py-4 pe-md-5"}>
                  <h3 className={"d-none d-md-flex"}>
                    <img src={Vision} alt={"Vision"} />
                    Vision
                  </h3>
                  {data?.vision?.split("\n")?.map((text) => (
                    <p>{text}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
