import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql, Link, useStaticQuery } from "gatsby";
import * as style from "./index.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";

const isDev = (value) => (process.env.NODE_ENV === "development" ? value : "");

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicGlobals {
        nodes {
          dataRaw
        }
      }
    }
  `);
  const globals = get(data, "allPrismicGlobals.nodes[0].dataRaw");

  return (
    <footer className={style.footerBox}>
      <div className="section-center">
        {/*<StyledComponent>*/}
        <Row>
          <Col xxxl={5} xxl={5} xl={5} lg={5} md={6}>
            <img
              src={get(globals, "f_logo.url", [])}
              className={style.footerLogo}
            />
            {/*<PrismicRichText field={get(globals, "f_title", [])}/>*/}
            <div className={style.footerDescription}>
              <PrismicRichText field={get(globals, "f_description", [])} />
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6}>
            <Row>
              <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>
                <ul className={style.listCom}>
                  <li className={style.listFirst}>
                    <Link to="/#services">Services</Link>
                  </li>
                  <li>
                    <Link to="/#services">Maintenance Services</Link>
                  </li>
                  <li>
                    <Link to="/#services">Environmental Services</Link>
                  </li>
                  <li>
                    <Link to="/#services">Minor Works</Link>
                  </li>
                  <li>
                    <Link to="/#services">Interior Design</Link>
                  </li>
                </ul>
              </Col>
              <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>
                <ul className={style.listCom}>
                  <li className={style.listFirst}>
                    <Link to="/#aboutUs">About</Link>
                  </li>
                  <li>
                    <Link to={"/#services"}>Services</Link>
                  </li>
                  <li>
                    <Link to={"/#industries"}>Industries we serve</Link>
                  </li>
                  <li>
                    <Link to={"/#aboutUs"}>About Us</Link>
                  </li>
                  {/*<li>*/}
                  {/*<Link to={"#"}>Testimonials</Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to={"/#contactUs"}>Contact Us</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={6} className={style.hideCel}></Col>
          <Col xxxl={3} xxl={3} xl={3} lg={3} md={6}>
            {/*<ul className={`${style.listCom} ${style.listDetail}`}>*/}
            {/*<li className={style.listFirst}>*/}
            {/*<Link to={"tel:+61458233948"}>*/}
            {/*Reach out to us*/}
            {/*</Link>*/}
            {/*</li>*/}

            {/*<li className={style.listMobile}>*/}
            {/*<Link to={"tel:+61458233948"}>*/}
            {/*0458 233 948*/}
            {/*</Link>*/}
            {/*</li>*/}
            {/*</ul>*/}
          </Col>
          <Col>
            <div className={style.copyRightBox}>
              <Link to={"/terms-conditions/"}>Terms and conditions</Link>
              <p>
                &copy;{new Date().getFullYear()} - KAYA | All right reserved
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
