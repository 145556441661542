import React, { useEffect } from "react";
import * as style from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";

const AboutUsSection = ({ data }) => {
  return (
    <div id="aboutUs" className="section-center">
      <section className={style.sectionBox}>
        <h1 className={style.mobileTitle}>About Us</h1>
        <div className={style.contentBox}>
          <div className={style.imgBox}>
            <img src={data?.au_image} data-aos="zoom-in-down" alt={""} />
            <div className={style.greenBox} data-aos="zoom-in-up"></div>
          </div>
          <div className={style.parmBox}>
            <div className={style.destopTitle}>
              <PrismicRichText field={data?.au_title} />
            </div>
            <PrismicRichText field={data?.au_description} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsSection;
